import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import Card from "./Card.jsx";
import {
  updateStep,
  saveEditedStepIds,
  setStepOrdered,changeInStepIsThere,
  reorderStep
} from "./../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";

export const Container = (steps, formId,isPreview) => {
  const [cards, setCards] = useState(steps.steps);
const  docDetails = useSelector((state)=>state.procedureDetails)
  const [media, setMedia] = useState("");
  const [isCardMoved, setIsCardMoved] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setCards(steps.steps);
  }, [steps.steps]);

  useEffect(() => {
    sortCards();
  }, [cards]);

  const sortCards = () => {
    cards.sort(function (a, b) {
      return a.order_id - b.order_id;
    });
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    dispatch(setStepOrdered(true));
    setIsCardMoved(true);
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })  
    );
  }, []);

  const mediaFullScreen = (media) => {
    setMedia(media);
  };
const changeInStep = (card)=>{
if(card?.lastApprovedVersion?.stepId!==undefined){ 
  if(card.lastApprovedVersion.stepTitle!==card.stepTitle)  {dispatch(changeInStepIsThere(card.stepId));return true}  

else if(card.lastApprovedVersion.stepDescription!==card.stepDescription)  {dispatch(changeInStepIsThere(card.stepId));return true}  

else if(card?.lastApprovedVersion?.media?.length || card?.media?.length) {
  const currentMedia = card.media?.map((obj) => obj.fileName);
  const lastApprovedMedia =card.lastApprovedVersion.media?.map((obj) => obj.fileName);
  const addedMedia = currentMedia?.filter(
    (objA) =>
      !lastApprovedMedia?.some(
        (objB) => JSON.stringify(objA) === JSON.stringify(objB)
      )
  );
  const deletedMedia = lastApprovedMedia?.filter(
    (objB) =>
      !currentMedia?.some(
        (objA) => JSON.stringify(objB) === JSON.stringify(objA)
      )
  );
  if(addedMedia[0]!==deletedMedia[0])
  {dispatch(changeInStepIsThere(card.stepId))
   return true;
  }
}
else return false;
}
else{
  return false
}
}
const newStep = (step)=>{
  if(step?.lastApprovedVersion?.stepId===undefined && step.stepId!=="001" && formId && docDetails?.lastApprovedVersion?.documentId!==undefined )
  {
    return true
  }
  else return  false;

}
  // Render Each steps
  const renderCard = (card, index) => {
    
   
    if (card) {
      if (isCardMoved) {
        const cardDetails = {
          title: card.stepTitle,
          description: card.stepDescription,
          order_id: index,
          stepId: card.stepId,
          media: card.media,
         
        };
        dispatch(reorderStep(cardDetails));
        if (card.stepId) {
          dispatch(saveEditedStepIds(card.stepId));
        }
      }

      return (
        <Card
          key={card.stepId}
          index={index}
          id={card.stepId}
          title={card.stepTitle}
          description={card.stepDescription}
          moveCard={moveCard}
          media={card.media}
          isPreview={steps.isPreview}
          disablePreview={steps.disableReorder}
          mediaFullScreen={mediaFullScreen}
          changed={changeInStep(card)}
          newStep ={newStep(card)}
        />
      );
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        {cards?.map((card, i) => renderCard(card, i))}
        {media && (
          <Modal className="modal-upload-image" open={media}>
            <button onClick={() => setMedia("")} className="image-close">
              X
            </button>
            <div className="modal-action-image">
              <img className="image-fullscreen" src={media.url} alt="" />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Container;
