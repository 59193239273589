import React, { useEffect, useState } from "react";
import HtmlDiff from "htmldiff-js";
import { connect } from "react-redux";
import {changeIsThere} from "./../../redux/actions";
const ChangeLog = (props) => {
  const [changedData, setChangedData] = useState([]);
  const [mediaChange, setMediaChange] = useState({});
  const [differencesOfArray, setDifferenceOfAArray] = useState({});
  const [resultArray, setResultArray] = useState([]);
  const [seed, setSeed] = useState(1);
  let arrayForDeletedStepIdentification = [];
  const [arrayAfterDeletion , setArrayAfterDeletion] = useState([]);
       const reset = () => {
            setSeed(Math.random());
        }

  useEffect(() => {
    reset();
    const handleClickOutside = (event) => {
      if (props.visible && !event.target.closest(".log-card")) {
        props.setShowChangeLog(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [props.visible]);

  function compareMediaArrays(currentMediaObj, lastApprovedMediaObj) {
   
    const currentMedia = currentMediaObj?.map((obj) => obj.fileName);
    const lastApprovedMedia =props.steps? lastApprovedMediaObj?.map((obj) => obj.fileName):lastApprovedMediaObj?lastApprovedMediaObj:[];
  
    const addedMedia = currentMedia?.filter(
      (objA) =>
        !lastApprovedMedia?.some(
          (objB) => JSON.stringify(objA) === JSON.stringify(objB)
        )
    );
    const deletedMedia = lastApprovedMedia?.filter(
      (objB) =>
        !currentMedia?.some(
          (objA) => JSON.stringify(objB) === JSON.stringify(objA)
        )
    );
    if(addedMedia[0]!==deletedMedia[0])
    {
      setMediaChange({
        addedMedia: addedMedia,
        deletedMedia: deletedMedia,
      });
    }
   
  }
  useEffect(()=>{
  if(changedData?.length > 0 ||  differencesOfArray.deletedSteps?.length>0 ||  differencesOfArray.newlyAddedStepArray?.length>0 ||  mediaChange?.addedMedia?.length > 0 || mediaChange?.deletedMedia?.length > 0)
  {
    props.changeIsThere(true)
  }
  },[changedData,differencesOfArray,mediaChange])

  const changeCheck = () => {
    if (!props.steps) {
    
      if (
        props?.form?.procedureDescription !==
        props?.procedureDetails?.lastApprovedVersion?.procedureDescription
      ) {
        setChangedData((prevData) => [...prevData, "procedureDescription"]);
      }
      if (
        props?.form?.procedureTitle !==
        props?.procedureDetails?.lastApprovedVersion?.procedureTitle
      ) {
        setChangedData((prevData) => [...prevData, "procedureTitle"]);
      }
      if (
        props?.form?.category !==
        props?.procedureDetails?.lastApprovedVersion?.category
      ) {
        setChangedData((prevData) => [...prevData, "category"]);
      }
    } else {
      if (
        props?.form?.title !==
        props?.editStepData?.lastApprovedVersion?.stepTitle
      ) {
        setChangedData((prevData) => [...prevData, "title"]);
      }
      if (
        props?.form?.description !==
        props?.editStepData?.lastApprovedVersion?.stepDescription
      ) {
        setChangedData((prevData) => [...prevData, "description"]);
      }
    }
  };
  const changeInText = (dataChanged) => {
    if (!props.steps) {
      const procedureDataDifference = HtmlDiff.execute(
        props?.procedureDetails?.lastApprovedVersion[dataChanged],
        props?.form[dataChanged]
      );
      return procedureDataDifference;
    } else {
      const fieldName =
        dataChanged === "title" ? "stepTitle" : "stepDescription";

      const stepDataDifference = HtmlDiff.execute(
        props?.editStepData?.lastApprovedVersion?.[fieldName],
        props?.form?.[dataChanged]
      );
      return stepDataDifference;
    }
  };
 
 
  const arrayOfArrays = [
    ['stepId1', 'stepTitle1'],
    ['stepId2', 'stepTitle2'],
    ['stepId1', 'stepTitle1'],
    ['stepId3', 'stepTitle3'],
    ['stepId4', 'stepTitle4'],
    ['stepId2', 'stepTitle2'],
];



  // // Function to compare two arrays and find the differences
  function compareArrays(arrayA, arrayB) {
 
    const deletedSteps = arrayA?.filter(itemA =>
      !arrayAfterDeletion?.some(itemB => itemB[0] === itemA[0])
    );

    const newlyAddedStep = arrayB?.filter(
      (itemB) =>
        !arrayA?.some((itemA) =>  itemA[0] === itemB[0] )
    );
    
    const nameChangedStep = arrayA?.map((itemA) => 
    arrayB?.map((itemB) => {
        if (itemA[0] === itemB[0] && itemA[1] !== itemB[1]) {
            return [itemA[1], itemB[1] ];
        }
        // Return null for cases where condition is not met
        return null;
    })
    
).flat().filter(Boolean);
//let nameChangedStepArray =Array.from(new Set(nameChangedStep?.map(JSON.stringify)), JSON.parse);
let newlyAddedStepArray = Array.from(new Set(newlyAddedStep?.map(JSON.stringify)), JSON.parse);

    return {
      deletedSteps,
      newlyAddedStepArray,
    };
  }
  //function to store id and title in an array
  function extractAndStore(jsonDataArray) {
    const uniqueStepIds = {};
    for (const jsonData of jsonDataArray) {
      const { stepId, stepTitle  } = jsonData;
   
      if (!uniqueStepIds[stepId]) {

        // Add the stepId to the uniqueStepIds object
        uniqueStepIds[stepId] = true;

        //to check if the step is not a default step
        if( jsonData.stepId!=="001" && jsonData.stepTitle!=="Step Name"){
          arrayForDeletedStepIdentification.push( [stepId, stepTitle]);
    setArrayAfterDeletion(arrayForDeletedStepIdentification)
        // Add the entry to resultArray
        setResultArray(prevArrayData => [
            ...prevArrayData,
            [stepId, stepTitle],
        ]);
    }
  }
    }
  }

  function findObjectById(array, id) {
    return array.find(obj => obj.stepId === id);
}


  useEffect(() => {
    (props?.procedureDetails?.lastApprovedVersion?.documentId !== undefined ||
      props?.editStepData?.lastApprovedVersion?.stepId !== undefined) &&
      changeCheck();
     
const foundObject = findObjectById(props.stepDetails, props.editStepData.stepId);

    compareMediaArrays(
      props.steps?foundObject.media:props?.procedureDetails?.media,
      props.steps?foundObject.lastApprovedVersion?.media: props?.procedureDetails?.lastApprovedVersion?.media
    );
  }, [props.form, props?.procedureDetails,props.stepDetails]);
  useEffect(() => {
  
    extractAndStore(props.stepDetails);
  }, [props.stepDetails]);
  useEffect(() => {
    //resultArray[0] === stepId  and resultArray[1] === stepName
    if (resultArray.length && resultArray[0]!=="001") {
      
      // Call the function to compare arrays lastapprovedsteps array and result array
      const differencesOfArray = compareArrays(
        props?.procedureDetails?.lastApprovedSteps,
        resultArray
      );
      setDifferenceOfAArray(differencesOfArray);
    }
  }, [resultArray]);
  const fieldName = (name) => {
    const fieldNamesMap = {
      procedureDescription: "Procedure Description",
      category: "Category",
      procedureTitle: "Procedure Title",
      title: "Step Title",
      description: "Step Description"
    };
    return fieldNamesMap[name];
  };

  const fileNameExtraction = (fileName) => {
  
    return fileName.fileName ?fileName.fileName.match(/[^/]*$/)[0]:fileName.match(/[^/]*$/)[0];
  
  };
  const noChangeFunc = ()=>{
    
     if (!changedData?.length && !mediaChange?.addedMedia?.length  &&
      !mediaChange?.deletedMedia?.length && !differencesOfArray.deletedSteps?.length && !differencesOfArray.newlyAddedStepArray?.length )
       
        {  return  <p className="text-no-change">No Change</p>}
        else if (props.steps && !changedData?.length && !mediaChange?.addedMedia?.length  &&
          !mediaChange?.deletedMedia?.length ){
            return  <p className="text-no-change">No Change</p>
        }
  }
  return (
    <div
      className={`${
        props.visible  ? "slide-in" : "slide-out"
      } log-card feedback-card`}
    
      key={seed}
    >
      {changedData.length !== 0 && <h2>{props.steps?"Step Change":"Procedure Change"}</h2>}
      {changedData.length > 0 &&
        [...new Set(changedData)].map((dataEdited) => (
          <>
            <div>
              <h4>
                The {fieldName(dataEdited)} is changed 
              </h4>
              <p
                dangerouslySetInnerHTML={{ __html: changeInText(dataEdited) }}
              />
            </div>
            <br></br>
          </>
        ))}
     {/* {(differencesOfArray.deletedSteps?.length!==0 ||differencesOfArray.newlyAddedStep?.length!==0|| differencesOfArray.nameChangedStep?.length!==0 )&&!props.steps &&  <h2>Step Change</h2>} */}
      {differencesOfArray.deletedSteps && !props.steps &&
        [...new Set(differencesOfArray.deletedSteps)].map((step) => (
          <div>
            The step <span className="deletedStep">{step[1]}</span> is deleted.
          </div>
        ))}
      {differencesOfArray.newlyAddedStepArray  && !props.steps &&
        differencesOfArray.newlyAddedStepArray.map((step) => (
          <div>
            The step <span className="newlyAdded">{step[1]}</span> is added.
          </div>
        ))}
   
      <br />
      {(mediaChange?.addedMedia?.length > 0 ||
        mediaChange?.deletedMedia?.length > 0 ) && <h2>Media Changes</h2>}
      {mediaChange?.addedMedia?.length > 0 &&
        mediaChange.addedMedia.map((mediaName) => (
          <div>
            The video{" "}
            <span className="newlyAdded">{fileNameExtraction(mediaName)}</span>{" "}
            is added.
          </div>
        ))}
      {mediaChange?.deletedMedia?.length > 0 &&
        mediaChange.deletedMedia.map((mediaName) => (
          <div>
            The video{" "}
            <span className="deletedStep">{fileNameExtraction(mediaName)}</span>{" "}
            is deleted.
          </div>
        ))}
      {noChangeFunc()}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    procedureDetails: state.procedureDetails,
    editStepData: state.editStepData,
    stepDetails: state.stepDetails,
  };
};
export default connect(mapStateToProps, {changeIsThere})(ChangeLog);
