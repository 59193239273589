import { combineReducers } from "redux";

const loginReducer = (userInfo = null, action) => {
  if (action.type === "LOGIN") {
    return {
      ...userInfo,
      username: action.payload.split("@")[0],
      userId: action.payload,
      authenticated: true,
      signupProcess: false,
      resetPasssword: false,
    };
  }
  return userInfo;
};

const activeTimer = (time = null, action) => {
  if (action.type === "ACTIVE_TIME") {
    return action.payload;
  }
  return time;
};

const getGeoLocationReducer = (loc = { lat: "", lng: "" }, action) => {
  if (action.type === "LOCATION") {
    return action.payload;
  }
  return loc;
};

const addStepReducer = (
  stepDetails = [
    {
      stepId: "001",
      stepTitle: "Step Name",
      stepDescription: [
        {
          type: "paragaph",
          children: [{ text: " " }],
        },
      ],
    },
  ],
  action
) => {
  const defaultStep = [
    {
      stepId: "001",
      stepTitle: "Step Name",
      stepDescription: [
        {
          type: "paragaph",
          children: [{ text: " " }],
        },
      ],
    },
  ];
  if (action.type === "ADD_STEP") {
    stepDetails=[...stepDetails ,action.payload];
  }
  if (action.type === "UPDATE_DEFAULT_STEP") {
    stepDetails = [];
    stepDetails=[...stepDetails ,action.payload];
  }
  if (action.type === "RESET_STEPDETAILS") {
    stepDetails = defaultStep;
  } else if (action.type === "REMOVE_STEP") {
    stepDetails = stepDetails.filter((data) => data.stepId !== action.payload);
    if (stepDetails.length === 0) {
      stepDetails = defaultStep;
    }
  } else if (action.type === "UPDATE_STEP") {
    const index = stepDetails.findIndex((step) => step.stepId === action.payload.stepId);
      if (index !== -1) {
        const updatedStepDetails = [...stepDetails];
        updatedStepDetails[index] = action.payload;
        return updatedStepDetails;
      }
     
  }
  else  if (action.type === "REORDER_STEP") {
    stepDetails.map((stepData) => {
      if (
        stepData.stepId.length > 0 &&
        stepData.stepId === action.payload.stepId
      ) {
        stepData.order_id = action.payload.order_id;
       
      }
    });
  }

  return stepDetails;
};

const setSelectedTemplateReducer = (template = "", action) => {
  if (action.type === "SET_SELECTED_TEMPLATE") {
    template = action.payload;
  }
  return template;
};

const setEnableEditStepReducer = (editStep = false, action) => {
  if (action.type === "SET_ENABLE_EDIT") {
    editStep = action.payload;
  }
  return editStep;
};

const setEditStepDataReducer = (editStepData = [], action) => {
  if (action.type === "EDIT_STEP_DATA") {
    editStepData = action.payload;
  }
  if (action.type === "RESET_EDIT_STEP") {
    editStepData = [];
  }
  return editStepData;
};

const saveEditedStepId = (editedStepIds = [], action) => {
  if (action.type === "SAVE_EDITED_STEP_ID") {
    editedStepIds.push(action.payload);
  }
  if (action.type === "RESET_EDIT_STEPIDS") {
    editedStepIds = [];
  }

  return editedStepIds;
};

const setProcedureDataReducer = (procedureDetails = [], action) => {
  if (action.type === "SAVE_PROCEDURE_DATA") {
    procedureDetails = action.payload;
  }
  if (action.type === "RESET_PROCEDURE_DATA") {
    procedureDetails = [];
  }
  return procedureDetails;
};

const setDocumentListReducer = (documentList = [], action) => {
  if (action.type === "SAVE_DOCUMENT_LIST") {
    documentList = action.payload;
  }
  return documentList;
};

const setPreviewReducer = (isPreview = false, action) => {
  if (action.type === "SET_PREVIEW") {
    isPreview = action.payload;
  }
  return isPreview;
};

const setSaveReviewerReducer = (isReviewer = false, action) => {
  if (action.type === "SAVE_IS_REVIEWER") {
    isReviewer = action.payload;
  }
  return isReviewer;
};

const setIsNewDocument = (isNewDocument = false, action) => {
  if (action.type === "IS_NEW_DOCUMENT") {
    isNewDocument = action.payload;
  }
  return isNewDocument;
};

const setIsFromFeedback = (isFromFeedback = false, action) => {
  if (action.type === "IS_FROM_FEEDBACK") {
    isFromFeedback = action.payload;
  }
  return isFromFeedback;
};

const setIsFeedback = (feedback = [], action) => {
  if (action.type === "IS_NEW_FEEDBACK") {
    feedback = action.payload;
  }
  return feedback;
};

const setIsdocumentId = (documentId = "", action) => {
  if (action.type === "IS_NEW_DOCUMENTID") {
    documentId = action.payload;
  }
  return documentId;
};

const setFeedbackData = (feedbackData = [], action) => {
  if (action.type === "FEEDBACK_DATA") {
    feedbackData = action.payload;
  }
  if (action.type === "CLEAR_FEEDBACKDATA") {
    feedbackData = [];
  }
  return feedbackData;
};

const saveDocumentData = (docMedia = [], action) => {
  if (action.type === "SAVE_DOCUMENT_MEDIA") {
    docMedia = action.payload;
  }
  if (action.type === "RESET_DOCUMENT_MEDIA") {
    docMedia = [];
  }
  return docMedia;
};

const setStepData = (stepData = [], action) => {
  if (action.type === "SET_STEP_DATA") {
    stepData = action.payload;
  }
  if (action.type === "RESET_EDIT_STEP") {
    stepData = [];
  }
  return stepData;
};

const setStepMediaFiles = (stepMediaFiles = [], action) => {
  if (action.type === "SET_STEP_MEDIA") {
    stepMediaFiles = action.payload;
  }
  if (action.type === "RESET_STEP_MEDIA") {
    stepMediaFiles = [];
  }
  return stepMediaFiles;
};

const setNotificationCount = (count = 0, action) => {
  if (action.type === "NOTIFICATION_COUNT") {
    count = action.payload;
  }
  return count;
};

const setFeatureFlagReducer = (featureFlag = [], action) => {
  if(action.type === "SET_FEATURE_FLAG") {
    featureFlag = action.payload;
  }
  return featureFlag;
};

const enableViewFeedback = (viewFeedback = false, action) => {
  if (action.type === "VIEW_FEEDBACK") {
    viewFeedback = action.payload;
  }
  if (action.type === "RESET_FEEDBACK") {
    viewFeedback = action.payload;
  }
  return viewFeedback;
};

const enablePreviewButtonReducer = (ablePreview = false, action) => {
  if (action.type === "SET_ENABLE_PREVIEW") {
    ablePreview = action.payload;
  }

  return ablePreview;
};
const editFormReducer = (edited = false, action) => {
  if (action.type === "SET_FORM_EDITED") {
    edited = action.payload;
  }

  return edited;
};

const editStepFormReducer = (edited = false, action) => {
  if (action.type === "SET_STEP_FORM_EDITED") {
    edited = action.payload;
  }

  return edited;
};
const stepOrderedReducer = (ordered = false, action) => {
  if (action.type === "IS_STEP_ORDERED") {
    ordered = action.payload;
  }

  return ordered;
};
const stepEditedReducer = (edited = false, action) => {
  if (action.type === "IS_STEP_EDITED") {
    edited = action.payload;
  }

  return edited;
};

const saveMediaUrlReducer = (url = null, action) => {
  if (action.type === "MEDIA_ADDED") {
    url = action.payload;
  }

  return url;
};

const saveDocTitleReducer = (docTitle = "", action) => {
  if (action.type === "SAVE_DOC_TITLE") {
    docTitle = action.payload;
  }

  return docTitle;
};
const checkIfBlankFields = (blankField = true, action) => {
  if (action.type === "BLANK_FIELDS") {
    blankField = action.payload;
  }

  return blankField;
};
const showPopupInIntructionPage = (showPopup = false, action) => {
  if (action.type === "SHOW_POPUP") {
    showPopup = action.payload;
  }

  return showPopup;
};

const saveMediatorenderreducer = (mediarender = "", action) => {
  if (action.type === "MEDIA_TO_RENDER") {
    mediarender = action.payload;
  }

  return mediarender;
};
const savePrevDocDataReducer = (prevDocData = [], action) => {
  if (action.type === "SAVE_PREV_DATA") {
    prevDocData = action.payload;
  }
  if (action.type === "RESET_PREV_DATA") {
    prevDocData = [];
  }
  return prevDocData;
};
const saveNewStepIdReducer = (newStepId = [], action) => {
  if (action.type === "SAVE_NEW_STEP_ID") {
    newStepId.push(action.payload);
  }
  if (action.type === "REMOVE_NEW_STEP_ID") {
    if (action.payload.length === 0) {
      return [];
    }
    newStepId.pop();
  }
  return newStepId;
};
const changeIsThereReducer = (changeIsThere = false, action) => {
  if (action.type === "CHANGE_IS_THERE") {
    changeIsThere = action.payload;
  }

  return changeIsThere;
};
const changeInStepIsThereReducer = (changeInStepIsThere = "", action) => {
  if (action.type === "CHANGE_IN_STEP_IS_THERE") {
    changeInStepIsThere = action.payload;
  }

  return changeInStepIsThere;
};
const aimodalReducer = (aimodal = false, action) => {
  if (action.type === "AI_MODAL_OPEN") {
    aimodal = action.payload;
  }

  return aimodal;
};

export default combineReducers({
  userInfo: loginReducer,
  timer: activeTimer,
  location: getGeoLocationReducer,
  stepDetails: addStepReducer,
  template: setSelectedTemplateReducer,
  editStep: setEnableEditStepReducer,
  editStepData: setEditStepDataReducer,
  editedStepIds: saveEditedStepId,
  procedureDetails: setProcedureDataReducer,
  documentList: setDocumentListReducer,
  isPreview: setPreviewReducer,
  isReviewer: setSaveReviewerReducer,
  isNewDocument: setIsNewDocument,
  isFromFeedback: setIsFromFeedback,
  feedback: setIsFeedback,
  documentId: setIsdocumentId,
  feedbackData: setFeedbackData,
  docMedia: saveDocumentData,
  stepData: setStepData,
  stepMediaFiles: setStepMediaFiles,
  count: setNotificationCount,
  featureFlag: setFeatureFlagReducer,
  viewFeedback: enableViewFeedback,
  enablePreviewButton: enablePreviewButtonReducer,
  editForm: editFormReducer,
  editStepForm: editStepFormReducer,
  stepOrdered: stepOrderedReducer,
  stepEdit: stepEditedReducer,
  mediaUrl: saveMediaUrlReducer,
  docTitle: saveDocTitleReducer,
  blankField: checkIfBlankFields,
  showPopupRedux: showPopupInIntructionPage,
  mediarender: saveMediatorenderreducer,
  prevDocData: savePrevDocDataReducer,
  newStepId: saveNewStepIdReducer,
  changeIsThere:changeIsThereReducer,
  changeInStepIsThere:changeInStepIsThereReducer,
  aimodal: aimodalReducer
});
