function colorName(solution_status) {
    if (solution_status.toUpperCase() === "NEW") {
      return "new status-label";
    } else if (solution_status.toUpperCase() === "DECLINED" ) {
      return "decliened status-label";
    } else if ( solution_status.toUpperCase() === "RESOLVED" ) {
      return "approved status-label";
    } else {
      return "new status-label";
    }
  }
  
  export { colorName };