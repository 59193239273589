import React, {
  useEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import { Modal, Button } from "semantic-ui-react";
import { saveDocumentfeedback } from "./../../redux/actions/index";
import { updateFeedbackStatus, getFeedbackdocument } from "../apiCall";

const ViewFeedback = (props) => {
  const [showFullDesc, setShowFullDesc] = useState(false);
  const [media, setMedia] = useState("");
  const [confirmationPopupVisible, setConfirmationPopupVisible] =
    useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState();
  const [popupMessage, setPopupMessage] = useState();
  const renderMedia = (data) => {
    var mediaFiles = [];
    for (let i = 0; i < data.media.length; i++) {
      mediaFiles.push(
        <div>
          <a
            style={{ fontSize: "11px" }}
            onClick={() => setMedia(data.media[i])}
          >
            {data.media[i].fileName.split("/")[2]}
          </a>
        </div>
      );
    }
    return mediaFiles;
  };

  const handleFeedBackStatus = async (e, status, feedback) => {
    e.preventDefault();
    var confirmationMessgae;
    if (status === "DECLINED") {
      confirmationMessgae = {
        header: "Decline Feedback",
        body: "Are you sure you want to decline this feedback?",
        desc: "",
      };
    } else {
      confirmationMessgae = {
        header: "Resolve Feedback",
        body: "Are you sure you want to mark this feedback as resolved?",
        desc: "By resolving, you are indicating that the issue or suggestion brought up in this feedback has been addressed or no longer requires attention.",
      };
    }
    setPopupMessage(confirmationMessgae);
    setConfirmationPopupVisible(true);
    const data = {
      feedback,
      status,
    };
    setSelectedFeedback(data);
  };

  const feedbackAction = async () => {
    const data = {
      status: selectedFeedback.status,
    };
    await updateFeedbackStatus(
      selectedFeedback.feedback.documentId,
      selectedFeedback.feedback.stepId[0],
      selectedFeedback.feedback.commentId,
      data
    );
    await getFeedbackData(selectedFeedback.feedback);
    setConfirmationPopupVisible(false);
  };

  const getFeedbackData = async (feedback) => {
    await getFeedbackdocument(feedback.documentId).then(
      (res) => {
        props.saveDocumentfeedback(res.data.data.steps);
      }
    );
  };

  return (
    <div>
       <>
        <div className="feedback-card">
        {media.ext === "png" ||
        media.ext === "PNG" ||
        media.ext === "jpeg" ||
        media.ext === "JPEG" ||
        media.ext === "jpg" ||
        media.ext === "JPG" ? (
          <Modal className="modal-upload-image" open={media}>
            <button onClick={() => setMedia("")} className="image-close">
              X
            </button>
            <div className="modal-action-image">
              <img className="image-fullscreen" src={media.url} alt="image" />
            </div>
          </Modal>
        ) : (
          <Modal className="modal-upload-image" open={media}>
            <button onClick={() => setMedia("")} className="image-close">
              X
            </button>
            <div className="modal-action-image">
              <video preload="metadata" className="image-fullscreen" controls>
                <source className="photo" src={media.url} alt="" />
              </video>
            </div>
          </Modal>
        )}
        {confirmationPopupVisible && (
          <Modal
            className="modal-feedback-warning"
            open={confirmationPopupVisible}
            onClose={() => setConfirmationPopupVisible(false)}
          >
            <div className="modal-feedback-warning">
              <div>
                <h3>{popupMessage.header}</h3>
                <p>{popupMessage.body}</p>
                <p style={{ width: "500px" }}>{popupMessage.desc}</p>
              </div>
            </div>
            <div className="modal-action-delete">
              <Button
                className="modal-button-option-del1"
                onClick={() => setConfirmationPopupVisible(false)}
              >
                No
              </Button>
              <Button
                className="modal-button-option-del2"
                onClick={() => feedbackAction()}
              >
                Yes
              </Button>
            </div>
          </Modal>
        )}
          <div style={{ margin: "1em" }}>
            <p style={{ fontWeight: "500", fontSize: "12px" }}>
              Feedback Type: {props.feedback.typeOfFeedback}
            </p>
            <p style={{ fontWeight: "500", fontSize: "12px" }}>
              Feedback Title :{props.feedback.summaryOfFeedback}
            </p>
            <p style={{ fontWeight: "500", fontSize: "12px" }}>Description</p>
            <p style={{ fontSize: "11px" }}>
              {!showFullDesc && props.feedback.brief.length > 150
                ? props.feedback.brief.substring(0, 150) + "..."
                : props.feedback.brief}
            </p>
            { props.feedback.brief.length > 150 && <span
              onClick={() => setShowFullDesc(!showFullDesc)}
              style={{ fontWeight: "550", color: "#0971ce", fontSize: "12px" }}
            >
              {!showFullDesc ? "See more" : "See less"}
            </span>}
            {props.feedback?.media?.length > 0 && (
              <>
                <p style={{ fontWeight: "500", fontSize: "11px" }}>
                  Attachments
                </p>
                {renderMedia(props.feedback)}
              </>
            )}
            <div className="feedback-actions">
              {props.feedback.status === "NEW" ? (
                <>
                  <Button
                    className="declinebtn"
                    // disabled={disableCreateStep()}
                    onClick={(e) => handleFeedBackStatus(e, "DECLINED", props.feedback)}
                  >
                    Decline
                  </Button>
                  <Button
                    className="resolvebtn"
                    // disabled={disableCreateStep()}
                    onClick={(e) => handleFeedBackStatus(e, "RESOLVED", props.feedback)}
                  >
                    Resolve
                  </Button>
                </>
              ) : (
                <div>
                  <Button
                    className={
                      props.feedback.status === "RESOLVED" ? "resolvedbtn" : "declinedbtn"
                    }
                  >
                    {props.feedback.status === "RESOLVED" ? "RESOLVED" : "DECLINED"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  )
};
const mapStateToProps = (state) => {
  return {
    feedbackData: state.feedbackData,
  };
};
export default connect(mapStateToProps, { saveDocumentfeedback })(ViewFeedback);
