import React, { useState } from "react";
import {  Accordion, Popup, Icon } from "semantic-ui-react";
import AllSteps from "./Container";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import {
  addStep,
  setProcedureDetails,
  resetEditStepIds,
  saveIsReviewer,
  saveDocumentfeedback,
  saveDocumentMedia,

} from "../../redux/actions";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProcedureDiscriptionView from "./PreviewCard";


const PreviewReadonly = (props) => {
  const [media, setMedia] = useState("");
  const [activeIndex, setActiveIndex] = useState();
  const [dropDown, setDropDown] = useState(false);

  const mediaFullScreen = (media) => {
    setMedia(media);
  };

  const onClickSection = (e, props) => {
    setDropDown(!dropDown);
    let sectionIndex = props.index;

    let newActiveIndex = activeIndex === sectionIndex ? -1 : sectionIndex;

    setActiveIndex(newActiveIndex);
  };

  return (
    <div>
      <div className="preview-summary">
        <h4>Procedure title</h4>
        <p>{props.procedureDetails.procedureTitle}</p>
        <h4>Category</h4>
        <p>{props.procedureDetails.category}</p>
        <ProcedureDiscriptionView
          description={props.procedureDetails.procedureDescription}
          media={props.mediarender}
          fileDetails={props.docMedia.fileDetails}
          mediaFullScreen={mediaFullScreen}
        />
        {props.stepDetails[0].stepTitle !== "Step Name" && (
          <>
            <br></br>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={onClickSection}
              >
                Table of contents
                <div className="wrap-icons">
                  <Popup
                    style={{
                      fontSize: "10px",
                      background: "rgb(242 247 251)",
                    }}
                    trigger={
                      <Icon
                        name="chevron down"
                        index={1}
                        className="dropdown-icon action-icon"
                        style={{
                          transform: dropDown && "rotate(180deg)",
                          fontSize: "1.2em",
                        }}
                      />
                    }
                    position="top center"
                  >
                    {activeIndex !== 0 ? "Expand Content" : "Collapse Content"}
                  </Popup>
                </div>
              </Accordion.Title>

              <Accordion.Content
                active={activeIndex === 0}
                className="marginL2"
              >
                <div>
                  <ol>
                    {props.stepDetails.map((steps) => (
                      <li>{steps.stepTitle}</li>
                    ))}
                  </ol>
                </div>
              </Accordion.Content>
            </Accordion>
          </>
        )}
        {props.stepDetails[0].stepTitle !== "Step Name" && (
          <div style={{ marginTop: "20px", marginBottom: "40px" }}>
            <h4>Steps of procedure </h4>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              {props.stepDetails.length > 0 && (
                <AllSteps
                  steps={props.stepDetails}
                  isPreview="true"
                  disableReorder={true}
                />
              )}
            </DndProvider>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    procedureDetails: state.procedureDetails,
    stepDetails: state.stepDetails,
    template: state.template,
    editedStepIds: state.editedStepIds,
    isReviewer: state.isReviewer,

    feedbackData: state.feedbackData,
    isFromFeedback: state.isFromFeedback,
    docMedia: state.docMedia,
    mediarender: state.mediarender,
  };
};

export default connect(mapStateToProps, {
  setProcedureDetails,
  addStep,
  resetEditStepIds,
  saveIsReviewer,

  saveDocumentfeedback,
  saveDocumentMedia,
})(PreviewReadonly);
