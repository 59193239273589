import React, { useCallback, useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Button,
  Form,
  Modal,
  Icon,
  Popup,
  Loader,
  Message,
  Dimmer,
  Segment,
  Image
} from "semantic-ui-react";
import AllSteps from "./Container";
import {
  addStep,
  setSelectedTemplate,
  setEnableEditStep,
  setProcedureDetails,
  resetProcedureDetails,
  saveDocumentMedia,
  setPreview,
  resetEditStepIds,
  resetEditStep,
  saveIsReviewer,
  setNewDocument,
  resetDocumentMedia,
  resetFeedback,
  saveDocumentfeedback,
  setEnablePreview,
  setFormEdit,
  passMediaURL,
  setBlankFields,
  showPopupAction,
  mediafiletorender,
  saveEditedStepIds,
  savePrevData,
  setStepFormEdit,
  aiButtonon,resetStepMediaFiles
} from "./../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  saveNewDocument,
  updateDocument,
  saveEditedStep,
  deleteDocument,
  uploadMedia,
  logUserAction
} from "../apiCall";
import Feedbackpopup from "../Lexxtableforfeedback/FeedbackModal";
import ProgressBar from "@ramonak/react-progress-bar";
import FroalaEditor from "./FriolaEditor/FroalaEditor";
import useAutosave from "./useAutosave";
import KeycloakUserService from "../login/KeyCloakService";

import ChangeLog from "./ChangeLog";

const CreateInstructionsForm = (props) => {
  const fileRef = useRef();
  const imageRef = useRef();

  const validateProcedureStoreData = () => {
    if (
      props.procedureDetails?.procedureTitle?.length > 0 ||
      props.procedureDetails?.procedureDescription?.length > 0 ||
      props.procedureDetails?.category?.length > 0
    ) {
      return true;
    }
    return false;
  };
  const [media, setMedia] = useState("");
  const [editedStepIdsState, setEditedStepIdsState] = useState(
    props.editedStepIds
  );

  const [saveStep, setSaveStepDone] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [dissablebuttons, setDissableButtons] = useState(false);
  const [fileToBeRemoved, setFileToBeRemoved] = useState([]);
  const [aiPopup, setAiPopup] = useState(false);

  const [aiGenerateddesc, setAiGenerateddesc] = useState("");
  const [confirmaidesc, setConfirmaidesc] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editedFields, seteditedFields] = useState(
    props?.procedureDetails?.changeLog
      ? props?.procedureDetails?.changeLog
      : {
        procedureTitle: "",
        procedureDescription: "",
        category: "",
      }
  );
  const [changeLog, setShowChangeLog] = useState(false);
  const [stopAnimation , setStopAnimation] = useState(false)
  const [form, setForm] = useState(
    validateProcedureStoreData()
      ? props.procedureDetails
      : {
        procedureTitle: "",
        procedureDescription: "",
        category: "",
      }
  );
  const [formContentChange, setFormContentChange] = useState(false);
  const [description, setDescription] = useState(form.procedureDescription);
  //filedetails is used to display the media on UI
  const [fileDetails, setFileDetails] = useState(
    props.docMedia?.fileDetails?.length > 0 ? props.docMedia.fileDetails : []
  );


  //mediaToBeSaved is used to save the media in API
  const [mediaToBeSaved, setMediaToBeSaved] = useState([]);
  const [loading, setLoading] = useState(false);
  const [yesFromPopup, setYesFromPopup] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [showPendingAlert, setShowPendingAlert] = useState(false);
  const [aiModalloader, setAiModalloader] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [enableCancelPopup, setEnableCancelPopup] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteFileCollection, setDeleteFileCollection] = useState([]);
  const [mediaFilesDownloaded, setMediaFilesDownloaded] = useState(
    props.mediarender ? props.mediarender : []
  );
  const [documentData, setDcoumentData] = useState(
    props.procedureDetails?.documentId?.length > 0
      ? props.procedureDetails
      : null
  );

  const [uploadedMediaURL, setUploadedMedia] = useState();
  const [showToast, setShowToast] = useState(false);
  const defaultStep = {
    stepId: "001",
    stepTitle: "Step Name",
    stepDescription: [
      {
        type: "paragaph",
        children: [{ text: " " }],
      },
    ],
  };

  const categoryList = [
    {
      label: "ADCP/DVL",
      value: "ADCP/DVL",
    },
    {
      label: "Admin",
      value: "Admin",
    },

    {
      label: "Camera",
      value: "Camera",
    },
    {
      label: "Comm",
      value: "Comm",
    },
    {
      label: "Drone",
      value: "Drone",
    },

    {
      label: "Environmental",
      value: "Environmental",
    },
    {
      label: "Geophysical",
      value: "Geophysical",
    },
    {
      label: "GIS",
      value: "GIS",
    },
    {
      label: "GNSS",
      value: "GNSS",
    },
    {
      label: "INS/MRU",
      value: "INS/MRU",
    },
    {
      label: "MBES",
      value: "MBES",
    },
    {
      label: "Other Mapping Sensor",
      value: "Other Mapping Sensor",
    },
    {
      label: "SBES/SBP",
      value: "SBES/SBP",
    },
    {
      label: "Software",
      value: "Software",
    },
    {
      label: "USBL/LBL",
      value: "USBL/LBL",
    },
    {
      label: "USV",
      value: "USV",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];
  const abortControllerRef = useRef();
  // toggle autosave on every minute
  useAutosave(() => {
    !enableCancelPopup &&
      !saveInProgress &&
      formContentChange &&
      handleSaveDraft(false, true);
  }, 10 * 1000);
  useEffect(() => {
    localStorage.setItem("stepId", "");
    localStorage.setItem("orderId", "");
    props.setStepFormEdit(false);
    setEnableCancelPopup(props.showPopupRedux);
  }, [props.showPopupRedux]);

  useEffect(() => {
  
    props.resetFeedback(false);
    if (
      (form.procedureTitle.length !== 0 &&
        form.procedureDescription.length !== 0 &&
        form.procedureDescription.length !== 0 &&
        form.category.length !== 0) ||
      form.status === "APPROVED"
    ) {
      props.setEnableEditStep(true);
      props.setEnablePreview(true);
    } else {
      props.setEnableEditStep(false);
      props.setEnablePreview(false);
    }

    if (form.procedureDescription)
      if (
        form.procedureTitle.length !== 0 ||
        form.procedureDescription.length !== 0 ||
        form.category.length !== 0 ||
        form.status === "APPROVED"
      ) {
        props.setProcedureDetails(form);
      }
    props.resetEditStep(true);
    if (props.isReviewer) {
      if (props.procedureDetails.status !== "APPROVED") {
        props.stepEdit && props.setEnablePreview(true);
      }
      if (props.procedureDetails.status === "APPROVED") {
        props.editForm || props.stepEdit
          ? props.setEnablePreview(true)
          : props.setEnablePreview(false);
      }
    } else {
      if (props.procedureDetails.status === "APPROVED") {
        props.editForm || props.stepEdit
          ? props.setEnablePreview(true)
          : props.setEnablePreview(false);
      }
    }

    if (description !== form.procedureDescription && description !== "") {

      setForm({ ...form, ["procedureDescription"]: description });
      seteditedFields({
        ...editedFields,
        procedureDescription: KeycloakUserService.getUsername(),
      });
      props.setFormEdit(true);

      setFormContentChange(true);
      props.setEnablePreview(true);
    }

    if (
      validateProcedureStoreData() &&
      props.prevDocData?.procedureDescription !== form.procedureDescription
    ) {
      props.setFormEdit(true);
    }
    if (description === "") {
      props.setEnableEditStep(false);
      props.setEnablePreview(false);
    }
    if (
      (form.procedureTitle === "" ||
        description === "" ||
        form.category === "") &&
      props.editForm
    ) {
      props.setBlankFields(true);
    } else {
      props.setBlankFields(false);
    }

  }, [description, form, props.stepDetails]);

  useEffect(() => {
    if (props.stepEdit || props.stepOrdered) {
      props.setEnablePreview(true);
    }
  }, [props.stepEdit, props.stepOrdered]);

  useEffect(() => {
    setAiPopup(props.aimodal);
    logUserAction({
      documentId: props.procedureDetails.documentId, 
      action: 'ai-button'
    });
  }, [props.aimodal])

  useEffect(() => {
    showToast &&
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
  }, [showToast]);

  useEffect(() => {
    if (
      fileDetails?.length > 0 ||
      mediaFilesDownloaded?.length > 0 ||
      deleteFileCollection?.length > 0
    ) {
      const data = {
        fileDetails: fileDetails,
        media: mediaFilesDownloaded,
        mediaToBeDeleted: deleteFileCollection,
      };
      props.saveDocumentMedia(data);
    }
    if (deleteFileCollection?.length > 0) {
      handleSaveDraft(false, false, false);
    }
  }, [fileDetails, mediaFilesDownloaded, deleteFileCollection]);

  const getContent = useCallback((descriptionContent) => {
    setDescription(descriptionContent);
  }, []);

  /* Call this function when there is any change to the form */
  const handleChange = (e) => {
    let inputField = e.target.name;

    setForm({ ...form, [e.target.name]: e.target.value });
    seteditedFields({
      ...editedFields,
      [e.target.name]: KeycloakUserService.getUsername(),
    });
    props.setFormEdit(true);
    setFormContentChange(true);
    props.setEnablePreview(true);
  };

  const handleCreateStep = (e) => {
    props.resetEditStep(true);
    props.resetStepMediaFiles();
    e.preventDefault();
    props.history.push("/CreateInstructionsSteps");
  };
  
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handlecancelStep = async (e) => {
    e.preventDefault();

    //props.editForm will be true whenever there is a change in form state else it will be false.
    if (props.editForm || props.stepOrdered) {
      setEnableCancelPopup(true);
    } else {
      props.resetProcedureDetails();
      props.history.push("/");
    }
  };

  function returnStatus() {
    if (props.isReviewer) {
      return "IN_PROGRESS";
    } else {
      return "DRAFT";
    }
  }

  const handleCancelConfirmation = async (e) => {
    if (
      form.procedureTitle === "" ||
      form.procedureDescription === "" ||
      form.category === ""
    ) {
      props.showPopupAction(false);
      props.resetProcedureDetails();
      props.history.push("/");
    } else if (saveInProgress) {
      setShowToast(true);
      setEnableCancelPopup(false);
      props.showPopupAction(false);
    } else {
      e.preventDefault();

      setYesFromPopup(true);
      setEnableCancelPopup(false);
      if (props.isNewDocument && form?.documentId?.length > 0) {
        //Delete Document
        await deleteDocument(form.documentId);
        setYesFromPopup(false);
      } else if (
        form?.documentId?.length > 0 &&
        ((props.isReviewer && props.stepOrdered) || props.editForm)
      ) {
        await updateDocumentData(true);
      }
      props.showPopupAction(false);
      props.resetProcedureDetails();
      props.history.push("/");
    }
  };
  const handleNo = () => {
    setEnableCancelPopup(false);
    props.showPopupAction(false);
  };
  // Save procedure data
  const saveNewDocumentData = async () => {
    const data = {
      procedureTitle: form.procedureTitle,
      procedureDescription: form.procedureDescription,
      documentType: props.template,
      status: props.isReviewer ? "IN_PROGRESS" : "DRAFT",
      category: form.category,
      uploadedMedia: fileDetails,
    };
    const formData = createFormDocumentData(data);
    await saveNewDocument(formData).then((response) => {
      data.documentId = response.data.data.documentId;
      setForm({ ...form, ["documentId"]: response.data.data.documentId });
      setDcoumentData(data);
      props.setProcedureDetails(data);
      if (response.data.data.documents?.media?.length > 0) {
        setMediaFilesDownloaded(
          getMediaFiles(response.data.data.documents.media)
        );
        props.mediafiletorender(
          getMediaFiles(response.data.data.documents.media)
        );
      }
      mediaToBeSaved.length > 0 && setMediaToBeSaved([]);
    });
    // props.resetProcedureDetails();
  };

  // Create formdata to save doc
  const createFormDocumentData = (doc, isEditDoc = false) => {
    const formData = new FormData();

    const uploadedMedia = doc.uploadedMedia;
    let docDetail;
    if (isEditDoc) {
      docDetail = {
        procedureTitle: doc.procedureTitle,
        procedureDescription: doc.procedureDescription,
        documentType: doc.documentType,
        status: doc.status,
        category: doc.category,
        mediaToBeDeleted: doc.mediaToBeDeleted,
        editedFields: doc.editedFields,
      };
    } else {
      docDetail = {
        procedureTitle: doc.procedureTitle,
        procedureDescription: doc.procedureDescription,
        documentType: doc.documentType,
        status: doc.status,
        category: doc.category,
      };
    }
    formData.append("data", JSON.stringify(docDetail));
    if (uploadedMedia && uploadedMedia.length > 0) {
      for (let i = 0; i < uploadedMedia.length; i++) {
        formData.append("media", uploadedMedia[i]);
      }
    }
    return formData;
  };

  const handleSaveDraft = async (
    navigate = true,
    autosave = false,
    fromPreviewAndSubmit = false
  ) => {
    // this is used to  avoid unwanted api call during autosave , api won`t be calling without any chnage in the page.
    setFormContentChange(false);
    if (!saveInProgress) {
      setSaveInProgress(true);
      if (navigate || fromPreviewAndSubmit) setShowPendingAlert(true);

      if (form.documentId?.length > 0) {
        if (
          documentData?.procedureTitle !== form.procedureTitle ||
          documentData?.procedureDescription !== form.procedureDescription ||
          documentData?.category !== form.category ||
          props.isFromFeedback ||
          deleteFileCollection.length > 0 ||
          mediaToBeSaved.length > 0
        ) {
          await updateDocumentData();
        } else if (!autosave && navigate) {
          await updateDocumentData();
        }

        await updateDocumentSteps(autosave);
      } else if (
        form.procedureTitle.length > 0 &&
        form.procedureDescription &&
        form.procedureDescription.length > 0
      ) {
        // save new solution details
        await saveNewDocumentData();
      }
      props.resetDocumentMedia(true);
      if (navigate) {
        setShowPendingAlert(false);
        setShowSaveAlert(true);
        setTimeout(() => {
          props.history.push("/");
        }, 1000);
      }
      if (fromPreviewAndSubmit) {
        setShowPendingAlert(false);
        window.scroll(0, 0);
        props.history.push("/Preview");
      }
      setSaveInProgress(false);
    } else {
      setShowToast(true);
    }
  };
  const getNewMedia = () => {
    let length = props.prevDocData.length - 1;
    const newMediaFileName = mediaFilesDownloaded
      .map((media) => {
        const isUniqueFileName = props.prevDocData[length].media.every(
          (prevMedia) => {
            if (prevMedia.fileName !== media.fileName) {
              return media.fileName;
            }
          }
        );

        if (isUniqueFileName) {
          return media.fileName;
        }

        return null; // or any default value if needed
      })
      .filter(Boolean); // Filter out null values

    return newMediaFileName;
  };

  const updateDocumentData = async (isPrevVal = false) => {
    const data = {
      procedureTitle: isPrevVal
        ? props.prevDocData?.procedureTitle
        : form.procedureTitle,
      procedureDescription: isPrevVal
        ? props.prevDocData?.procedureDescription
        : form.procedureDescription,
      documentType: props.template,
      status: returnStatus(),
      category: isPrevVal ? props.prevDocData?.category : form.category,
      mediaToBeDeleted: isPrevVal ? getNewMedia() : deleteFileCollection,
      uploadedMedia: isPrevVal ? props.prevDocData?.media : mediaToBeSaved,
      editedFields: isPrevVal
        ? props.prevDocData?.changeLog
          ? props.prevDocData?.changeLog
          : []
        : editedFields,
    };

    const formData = createFormDocumentData(data, true);
    await updateDocument(formData, props.procedureDetails.documentId)
      .then((response) => {
        data.documentId = props.procedureDetails.documentId;

        setDcoumentData(data);
        props.setProcedureDetails(response.data.data.documents);
        setYesFromPopup(false);
        if (response.data.data.documents?.media?.length > 0) {

          setMediaFilesDownloaded(
            getMediaFiles(response.data.data.documents.media)
          );
          props.mediafiletorender(
            getMediaFiles(response.data.data.documents.media)
          );
        }
        //to avoid unwanted API calls during autosave
        mediaToBeSaved.length > 0 && setMediaToBeSaved([]);
        setDeleteFileCollection([]);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          KeycloakUserService.doLogout();
        }
      });
  };

  // Create formdata to save step
  const createFormData = (step, isEditstep = false) => {
    const formData = new FormData();
    let stepDetail = {
      stepTitle: step.stepTitle,
      description: step.stepDescription,
      order_id: step.order_id,
      _id: step.stepId,
    };
    formData.append("data", JSON.stringify(stepDetail));
    return formData;
  };

  const handlePreview = (e) => {
    e.preventDefault();
    handleSaveDraft(false, false, true);
  };

  const disableCreateStep = () => {
    return (
      !props.editStep ||
      props.stepDetails[0]?.stepTitle === defaultStep.stepTitle
    );
  };

  const sortSteps = (steps) => {
    steps.sort(function (a, b) {
      return a.order_id - b.order_id;
    });
  };

  const updateDocumentSteps = async (autosave) => {

    //Save steps aganist existing solution , condition is to call the function if step is ordered and it should not be called during autosave since it causes irrelevant api calls.

    !autosave && props.stepOrdered && (await saveStepsByDocument());
  };

  // Get unique steps Ids
  const getUniqueIds = (editStepIds) => {
    return editStepIds.filter(
      (item, index) => editStepIds.indexOf(item) === index
    );
  };

  // Save steps by solution id
  const saveStepsByDocument = async () => {
    sortSteps(props.stepDetails);
    const editedStepIdCollection = editedStepIdsState;
    props.resetEditStepIds(true);
    const editedStepIds = getUniqueIds(editedStepIdCollection);
    for (let i = 0; i < props.stepDetails.length; i++) {
      const step = props.stepDetails[i];
      for (let i = 0; i < editedStepIds.length; i++) {
        const stepId = editedStepIds[i];
        if (step.stepId === stepId) {
          const formData = createFormData(step, true);
          await saveEditedStep(
            props.procedureDetails.documentId,
            formData,
            stepId
          ).catch((err) => {
            if (err.response.status === 403) {
              KeycloakUserService.doLogout();
            }
          });
        }
      }
    }
    setEditedStepIdsState([]);
  };
  const getMediaFiles = (media) => {
    media.forEach((file) => {
      file.extention = file.fileName.split(".").pop();
    });
    setFileDetails([]);
    return media;
  };
  let submitAlertClass = showSaveAlert ? "modal-alert animate" : "modal-alert";
  let submitAlertStyles =
    showSaveAlert || showPendingAlert
      ? {}
      : { opacity: 0, visibility: "hidden", pointerEvents: "none" };

  const renderUploadFile = (e) => {
    if (saveInProgress) {
      setShowToast(true);
    } else {
      e.preventDefault();
      e.stopPropagation();
      fileRef.current.click();
    }
  };

  const renderImageInsertFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    imageRef.current.click();
  };
  const mediaFullScreen = (media) => {
    setMedia(media);
  };
  useEffect(() => {
    mediaToBeSaved.length > 0 && handleSaveDraft(false);
    mediaToBeSaved.length === 0 && setLoading(false);
  }, [mediaToBeSaved]);
  const handleVideoUpload = (e) => {
    setLoading(true);

    //when video is uploaded cancel popup need to show
    props.setFormEdit(true);
    setFormContentChange(true);
    handleInputChange(e, "video");
  };
  // Set Uploaded files
  const handleInputChange = async (e, type) => {
    e.preventDefault();
    const fileData = e.target.files;

    if (type === "video") {
      for (let i = 0; i < fileData.length; i++) {
        if (validateFile(fileData[i])) {
          if (!fileData[i].name) {
            const binary = atob(fileData[i].split(",")[1]);
            const array = [];
            var file;
            for (let i = 0; i < binary.length; i += 1) {
              array.push(binary.charCodeAt(i));
            }
            file = new Blob([new Uint8Array(array)], {
              type: "image/png",
            });
            file.name = `${new Date()}.png`;
            file.lastModifiedDate = new Date();
            const myFile = new File([file], file.name, {
              type: file.type,
            });
            setFileDetails((fileDetails) => [...fileDetails, myFile]);
            setMediaToBeSaved((fileDetails) => [...fileDetails, myFile]);
          }
          setFileDetails((fileDetails) => [...fileDetails, fileData[i]]);
          setMediaToBeSaved((fileDetails) => [...fileDetails, fileData[i]]);
        }
      }
    } else {
      const formFile = await getformData(fileData);
      setShowPendingAlert(true);
      await uploadMedia(formFile).then((res) => {
        setUploadedMedia(res.data.data.url);
        props.passMediaURL(res.data.data.url);
        setShowPendingAlert(false);
        setFileUpload(true);
        setShowSaveAlert(true);
        setTimeout(() => {
          setShowSaveAlert(false);
        }, 1000);
      });
    }
  };

  const getformData = async (file) => {
    const formData = new FormData();
    formData.append("media", file[0]);
    return formData;
  };

  const renderImagesAndVideos = () => {
    var rendreFiles = [];
    // Downloaded files
    if (mediaFilesDownloaded) {
      mediaFilesDownloaded.forEach((media) => {
        if (
          media.extention === "png" ||
          media.extention === "PNG" ||
          media.extention === "jpeg" ||
          media.extention === "JPEG" ||
          media.extention === "jpg" ||
          media.extention === "JPG"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <img
                    className="photo"
                    src={media.url}
                    typeof="image/png, image/jpeg, image/jpg"
                    alt=""
                    onClick={() => {
                      mediaFullScreen(media);
                    }}
                  />
                }
                position="left center"
              >
                {/* {media.fileName.split("//")[1]} */}
                {media.fileName.substring(media.fileName.lastIndexOf("/") + 1)}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFilePopupHandler(media.fileName, "media")}
              >
                X
              </button>
            </div>
          );
        }
        if (
          media.extention === "mp4" ||
          media.extention === "MP4" ||
          media.extention === "MOV" ||
          media.extention === "mov"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <video
                    className="photo"
                    onClick={() => {
                      mediaFullScreen(media);
                    }}
                  >
                    <source className="photo" src={media.url} alt="" />
                  </video>
                }
                position="left center"
              >
                {/* {media.fileName.split("//")[1]} */}
                {media.fileName.substring(media.fileName.lastIndexOf("/") + 1)}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFilePopupHandler(media.fileName, "media")}
              >
                X
              </button>
            </div>
          );
        }
      });
    }

    // Uploaded files
    if (fileDetails?.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (
          fileDetails[i].type === "image/png" ||
          fileDetails[i].type === "image/jpeg" ||
          fileDetails[i].type === "image/jpg" ||
          fileDetails[i].type === "image/JPG" ||
          fileDetails[i].type === "image/JPEG" ||
          fileDetails[i].type === "image/PNG"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <img
                    src={URL.createObjectURL(fileDetails[i])}
                    className="photo"
                    typeof="image/png, image/jpeg, image/jpg, image/*, JPEG/H.264"
                    onClick={() => {
                      mediaFullScreen(fileDetails[i]);
                    }}
                    alt=""
                  />
                }
                position="left center"
              >
                {fileDetails[i].name}
              </Popup>

              <button
                className="close-button-position"
                onClick={() =>
                  removeFilePopupHandler(fileDetails[i].name, "fileDetails")
                }
              >
                X
              </button>
            </div>
          );
        } else if (
          fileDetails[i].type === "video/mp4" ||
          fileDetails[i].type === "MOV" ||
          fileDetails[i].type === "mov" ||
          fileDetails[i].type === "video/quicktime" ||
          fileDetails[i].type === "video/*" ||
          fileDetails[i].type === "HEVC/H.264" ||
          fileDetails[i].type === "hevc/h.264"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <video
                    preload="metadata"
                    className="photo"
                    onClick={() => {
                      mediaFullScreen(fileDetails[i]);
                    }}
                  >
                    <source
                      className="photo"
                      src={URL.createObjectURL(fileDetails[i])}
                      alt=""
                    />
                  </video>
                }
                position="left center"
              >
                {fileDetails[i].name}
              </Popup>

              <button
                className="close-button-position"
                onClick={() =>
                  removeFilePopupHandler(fileDetails[i].name, "fileDetails")
                }
              >
                X
              </button>
            </div>
          );
        }
      }
    }
    return rendreFiles;
  };
  const handledraftAI = () => {
    setAiPopup(true);
  }

  const fetchWithCancellation = async (controller) => {
    try {

    
    const response = await fetch("https://api.xauthorplus-dev.lexxtechnologies.com/generation", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: form.procedureTitle,
        notes: inputValue,
        template: "vanoord",
      }),
      signal: controller.signal, // Attach the abort signal
    });
   
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const reader = response.body.getReader();
    let final = '';

    while (true) {
     
      const { done, value } = await reader.read();

      if (done  ) {
        break;
      }

      const decoder = new TextDecoder();
      const str = decoder.decode(value);
      final += str;
      setAiGenerateddesc(final);
    }
  
    setDissableButtons(false);
  }
  catch (error) {
    if (error.name === 'AbortError') {
      console.log('Fetch was cancelled');
    } else {
      console.error('Fetch error:', error);
    }
  } 
};
  const handleInputChangedesc = (e) => {
    setInputValue(e.target.value);
  };

  const handledraftaiapi = async () => {

    setConfirmaidesc(true);
    setDissableButtons(true);
    setAiGenerateddesc("")
// Create a new AbortController
abortControllerRef.current = new AbortController();
// Start the fetch with the new controller
fetchWithCancellation(abortControllerRef.current);
  }

  const handleStopStream = () => {    

  if (abortControllerRef.current) {
  setDissableButtons(false);
  // Cancel the fetch request
  abortControllerRef.current.abort();

}

};
  const handleConfirmaidesc = () => {
 
    getContent(aiGenerateddesc);
    setInputValue("");
    props.aiButtonon(false);
    setConfirmaidesc(false);
  }

  const removeFilePopupHandler = (mediaFileName, mediaType) => {
    setFileToBeRemoved([mediaFileName, mediaType]);
  };

  // Remove files
  const removeFile = (fileName, collectionName) => {
    setFileToBeRemoved([]);
    setLoading(true);
    const fileUpdatedCollection = [];
    switch (collectionName) {
      case "fileDetails":
        if (fileDetails.length > 0) {
          for (let i = 0; i < fileDetails.length; i++) {
            if (fileDetails[i].name !== fileName) {
              fileUpdatedCollection.push(fileDetails[i]);
            }
          }
          setFileDetails(fileUpdatedCollection);
        }
        break;
      case "media":
        if (mediaFilesDownloaded) {
          mediaFilesDownloaded.forEach((media) => {
            if (media.fileName !== fileName) {
              fileUpdatedCollection.push(media);
            } else {
              setDeleteFileCollection((deleteFileCollection) => [
                ...deleteFileCollection,
                media.fileName,
              ]);
            }
          });
          setMediaFilesDownloaded(fileUpdatedCollection);
          props.mediafiletorender(fileUpdatedCollection);
        }
        break;
      default:
        break;
    }
  };

  const getTransitionTime = () => {
    if (fileDetails.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (
          fileDetails[i].type === "video/mp4" ||
          fileDetails[i].type === "MOV" ||
          fileDetails[i].type === "mov" ||
          fileDetails[i].type === "video/quicktime" ||
          fileDetails[i].type === "video/*"
        ) {
          return "40s";
        }
        return "3s";
      }
    } else if (fileDetails.length > 5) {
      return "10s";
    }
    return "2s";
  };

  const validateFile = (fileData) => {
    const fileType = fileData.name.split(".").pop().toLowerCase();
    if (fileData.size > 100 * 1024 * 1024) {
      const fileSize = (fileData.size / 1024 / 1024).toFixed(2);
      alert(
        "The attached file (" +
        fileSize +
        ") exceeds the maximum size limit of 100 MB. Please reduce the file size and try again."
      );
      setLoading(false);
      return false;
    } else if (!(fileType === "mov" || fileType === "mp4")) {
      alert(
        "We do not accept files of this media type. Only file with mp4 and mov are allowed."
      );
      setLoading(false);
      return false;
    } else {
      const isFileExist = fileDetails?.some(
        (file) => file.name === fileData.name
      );
      const isFileAlreadySaved = mediaFilesDownloaded?.some(
        (file) => file.fileName.split("/").pop() === fileData.name
      );
      if (isFileExist || isFileAlreadySaved) {
        alert(
          "The file you are trying to upload has the same name as an existing file. Please rename the file or choose a different one."
        );
        setLoading(false);
        return false;
      }
    }
    return true;
  };
  const showChangeLog = () => {
    setStopAnimation(true)
    setShowChangeLog((changeLog) => !changeLog);
  };

  return (
    <div style={{ display: changeLog ? "flex" : "" }}>
      <div className="session-summary form-page-wrapper">
        <Modal open={loading}>
          <Loader active>Video is processing...</Loader>
        </Modal>
        <div>
          <Modal open={yesFromPopup}>
            <Loader active>Loading...</Loader>
          </Modal>
        </div>

        {popupVisible && (
          <Feedbackpopup
            popupVisible={popupVisible}
            history={props.history}
            feedbackData={props.feedbackData}
            handleClose={() => setPopupVisible(false)}
          />
        )}
        {enableCancelPopup && (
          <Modal
            className="modal-upload-delete"
            open={enableCancelPopup}
            onClose={() => {
              setEnableCancelPopup(false);
              props.showPopupAction(false);
            }}
          >
            <div className="modal-heading-delete">
              <div>
                <h3>Back to Home</h3>
                <p>
                  Changes have been made. Are you sure you do not want to save
                  before closing?
                </p>
              </div>

              <button
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  setEnableCancelPopup(false);
                  props.showPopupAction(false);
                }}
              >
                X
              </button>
            </div>
            <div className="modal-action-delete">
              <Button
                className="modal-button-option-del1"
                onClick={() => handleNo()}
              >
                No
              </Button>
              <Button
                className="modal-button-option-del2"
                onClick={(e) => handleCancelConfirmation(e)}
              >
                Yes
              </Button>
            </div>
          </Modal>
        )}
        {fileToBeRemoved[0]?.length && (
          <Modal
            className="modal-upload-delete"
            open={fileToBeRemoved}
            onClose={() => {
              setFileToBeRemoved([]);
            }}
          >
            <div className="modal-heading-delete">
              <div>
                <p>Do you want to save your changes?</p>
              </div>

              <button
                style={{ marginRight: "0.2rem" }}
                onClick={() => {
                  setFileToBeRemoved([]);
                }}
              >
                X
              </button>
            </div>
            <div className="modal-action-delete">
              <Button
                className="modal-button-option-del1"
                onClick={() => setFileToBeRemoved([])}
              >
                No
              </Button>
              <Button
                className="modal-button-option-del2"
                onClick={() =>
                  removeFile(fileToBeRemoved[0], fileToBeRemoved[1])
                }
              >
                Yes
              </Button>
            </div>
          </Modal>
        )}
        {showPendingAlert && (
          <div
            className={submitAlertClass}
            style={submitAlertStyles}
            variant={"warning"}
          >
            <div className="modal-alert-icon">
              <img
                className="PopUpIcon"
                src={require("../../assets/images/pending-shape.png")}
                alt=""
              />
            </div>
            <div className="model-alert-msg">
              <h4 className="Heading">
                {fileUpload
                  ? "File upload in progress"
                  : "Document saving in progress"}
              </h4>
              <p className="Para">
                {fileUpload
                  ? "Please wait until file upload completed"
                  : "Please wait while saving is completed"}
              </p>
              <ProgressBar
                completed={90}
                bgColor="#08bd5c"
                labelColor="#1e1818"
                transitionDuration={getTransitionTime()}
                transitionTimingFunction="ease-in"
                animateOnRender
                customLabel=" "
                height=""
              />
            </div>
          </div>
        )}
        {showToast && (
          <div className="toast  ">
            <Message color="red" size="large">
              <Message.Header>Message</Message.Header>
              <p>
                Autosave in progress! Please hold for a moment and do it again.
              </p>
            </Message>
          </div>
        )}
        {showSaveAlert && (
          <div
            className={submitAlertClass}
            style={submitAlertStyles}
            variant={"success"}
          >
            <div className="modal-alert-icon">
              <img
                className="PopUpIcon"
                src={require("../../assets/images/Shape 1.png")}
                alt=""
              />
            </div>
            <div className="model-alert-msg">
              <h4 className="Heading">
                {fileUpload ? "File Uploaded" : "Document Saved"}
              </h4>
              <p className="Para">
                {fileUpload
                  ? "Now you can copy the media url"
                  : "You can edit it again later"}
              </p>
              <ProgressBar
                completed={100}
                bgColor="#08bd5c"
                labelColor="#1e1818"
                customLabel=" "
                height=""
              />
            </div>
          </div>
        )}

        {media.type === "image/png" ||
          media.type === "image/jpeg" ||
          media.type === "image/jpg" ||
          media.type === "image/JPG" ||
          media.type === "image/JPEG" ||
          media.type === "image/PNG" ||
          media.extention === "png" ||
          media.extention === "PNG" ||
          media.extention === "jpeg" ||
          media.extention === "JPEG" ||
          media.extention === "jpg" ||
          media.extention === "JPG" ? (
          <Modal className="modal-upload-image" open={media}>
            <button onClick={() => setMedia("")} className="image-close">
              X
            </button>
            <div className="modal-action-image">
              <img
                className="image-fullscreen"
                src={
                  media.type !== undefined
                    ? URL.createObjectURL(media)
                    : media.url
                }
                alt="image"
              />
            </div>
          </Modal>
        ) : (
          <Modal className="modal-upload-image" open={media}>
            <button onClick={() => setMedia("")} className="image-close">
              X
            </button>
            <div className="modal-action-image">
              <video preload="metadata" className="image-fullscreen" controls>
                <source
                  className="photo"
                  src={
                    media.type !== undefined
                      ? URL.createObjectURL(media)
                      : media.url
                  }
                  alt=""
                />
              </video>
            </div>
          </Modal>
        )}
        <Form>
          <div style={{ width: "98%" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {props?.procedureDetails?.lastApprovedVersion?.documentId && (
           <Popup
           style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
           position="top left"
           trigger={<Button className={`cancelbtn ${props.changeIsThere && !stopAnimation?"changeLog-btn":"nochangeLog-btn"}`} onClick={() => showChangeLog()}>
                  Change log
                </Button>}
                >Click here to see the changes in Procedure Title, Procedure Description, Category and Media.</Popup>
              )}
            </div>

            <h3 style={{ backgroundColor: "#F5F5F5" }}>
              {props.template === "setupInstruction"
                ? "Create a New Setup Instruction"
                : "Create a New Operational Procedure"}<p style={{ display: "flex", float: "right" }}>  Autosaving in every 10 seconds.</p>
            </h3>
          </div>{" "}
          <Form.Field required className="input-wrap">
            <p className="form-label">Procedure Title* </p>
            <input
              name="procedureTitle"
              placeholder="Name the document"
              className="instruction-input"
              value={form.procedureTitle}
              onChange={handleChange}
              maxLength="250"
            />

            {form.procedureTitle === "" && props.editForm && (
              <span style={{ color: "red" }}>
                Procedure Title is a mandatory field.
              </span>
            )}
          </Form.Field>
          <Form.Field required className="input-wrap">
            <p className="form-label">Category*</p>
            <select
              value={form.category}
              name="category"
              placeholder="Click here to select"
              onChange={handleChange}
              className={
                form.category === ""
                  ? "category-dropdown-color"
                  : "category-dropdown"
              }
            >
              {categoryList.map((option) => (
                <>
                  <option key="blankKey" hidden value="">
                    Click here to select the category
                  </option>
                  <option value={option.value}>{option.label}</option>
                </>
              ))}
            </select>
            {form.category === "" && props.editForm && (
              <span style={{ color: "red" }}>
                Category is a mandatory field.
              </span>
            )}
          </Form.Field>
          <Form.Field required className={(form.category !== "" && form.procedureTitle !== "") ? "input-wrap" : "input-wrap ai-button-disable"}>
            <div className="desc-wrapup">
              <p className="form-label" style={{ paddingBottom: "8px" }}>Procedure Description*</p>
              {/* {props.featureFlag?.['draft-with-ai'] && (<Button className={(form.category !== "" && form.procedureTitle !== "") ? "ai-button" : "ai-button-disable"} onClick={form.category !== "" && form.procedureTitle !== "" && handledraftAI}><Icon name="microchip" /> Ai</Button>)} */}
            </div>
            <FroalaEditor
              content={form.procedureDescription}
              getContent={getContent}
              formdesc

            />

            {description === "" && props.editForm && (
              <span style={{ color: "red" }}>
                Procedure Description is a mandatory field.
              </span>
            )}
          </Form.Field>
          <div className="myContainer">
            <div className="take">
              <Button
                className="savebtn"
                onClick={renderUploadFile}
                disabled={!props.editStep}
              >
                Attach Video
              </Button>
              <Popup
                style={{
                  fontSize: "10px",
                  background: "rgb(242 247 251)",
                }}
                trigger={
                  <Icon
                    name="exclamation circle"
                    className="exclamation-icon"
                  />
                }
                position="right center"
              >
                Only file with mp4 and mov are allowed
              </Popup>
              <input
                type="file"
                id="video"
                className="takefile"
                name="media"
                multiple
                accept="video/mp4, video/*, HEVC/H.265, HEVC/H.264, JPEG/H.264, MOV, mov"
                ref={fileRef}
                onChange={(e) => {
                  handleVideoUpload(e);
                }}
              />
            </div>
            <div>
              {(fileDetails || mediaFilesDownloaded) && (
                <div className="image-wrap">{renderImagesAndVideos()}</div>
              )}
            </div>
          </div>
        </Form>
        <div className="input-wrap">
          <p className="form-label">Steps of procedure</p>
          <div className="card-container">
            {console.log("props.stepDetails",props.stepDetails)
            }
            <DndProvider backend={HTML5Backend}>
              <AllSteps steps={props.stepDetails} isPreview="false" />
            </DndProvider>
            {!disableCreateStep() && (
              <Button className="stepbtn" onClick={(e) => handleCreateStep(e)}>
                + Add new
              </Button>
            )}
          </div>
        </div>

        <div className="buttons">
          <Button
            className="savebtn"
            disabled={!props.editStep || showPendingAlert || showSaveAlert}
            onClick={() => handleSaveDraft()}
          >
            Save Draft
          </Button>

          <Button
            className="savebtn"
            disabled={
              !props.enablePreviewButton || showPendingAlert || showSaveAlert
            }
            onClick={(e) => {
              handlePreview(e);
            }}
          >
            {props.isReviewer ? "Preview and Publish" : "Preview and Submit"}
          </Button>
          <Button
            className="cancelbtn"
            onClick={(e) => handlecancelStep(e)}
            disabled={showPendingAlert || showSaveAlert}
          >
            Back to Home
          </Button>
        </div>
      </div>
      {
        aiPopup && (
          <Modal
            className="modal-upload-ai"
            open={aiPopup}
            onClose={() => {
              props.aiButtonon(false)
              setInputValue("")
            }}
          >
            {!confirmaidesc ? (<>
              {!aiModalloader ? (<>
                <div className="modal-heading-ai">
                  <h4>Generate with LexX</h4>
                  <div className="info-button">
                  <div className="popup-info-ai">
                        Please provide detailed key points to enable context-specific content generation.
                      </div>
                  </div>
                  <button onClick={() => {
                    props.aiButtonon(false);
                    setInputValue("")
                  }}>X</button>
                </div>
                <div className="wrap-input-ai">
                  <div className="keyword-wrap">
                    <p className="heading-keyword">Key Points<span style={{ color: "red", padding: "2px" }}>*</span></p>
                    
                  </div>
                  
                  <textarea
                    className="input-ai"
                    type="text"
                    placeholder="Eg Data : 
                    Create a Echoscope Document I need Information on:
                    Hardware Overview
                    Echoscope Sonar Head
                    Current Voltage Requirement
                    What is the 3D Productivity Station (3DPS)"
                    value={inputValue}
                    onChange={handleInputChangedesc}
                  ></textarea>
                </div>
                <div className="generate-draft">
                  <Button primary className={/^\s*$/.test(inputValue) ? "generate-draft-button ai-button-disable" : "generate-draft-button"} onClick={handledraftaiapi} >Generate</Button>
                </div>
              </>) : (<Segment className="loader-ai">
                <Dimmer active inverted>
                  <Loader inverted content='Loading' />
                </Dimmer>
              </Segment>)}
            </>
            ) : (<>
              {!aiModalloader ? (<>
                <div className="confirm-modal-ai">
                  <div className="regenerate-button-div">
                    <Button className={dissablebuttons ? "regenerate-button ai-button-disable" : "regenerate-button"} onClick={handledraftaiapi}><Icon name="redo" />Regenerate</Button>
                    <Button primary onClick={()=>{handleStopStream()}} disabled={!dissablebuttons}> Stop Generating</Button>
                  </div>
                  <div className="ai-generated-desc-confirm">{aiGenerateddesc}</div>

                </div>
                <div className={dissablebuttons ? "wrap-up-ai-buttons ai-button-disable" : "wrap-up-ai-buttons"}>
                  <Button primary onClick={() => { setConfirmaidesc(false) }} >Back</Button>
                  <Button primary onClick={handleConfirmaidesc} > Done </Button>
                  
                </div>
              </>) : (<Segment className="loader-ai">
                <Dimmer active inverted>
                  <Loader inverted content='Loading' />
                </Dimmer>
              </Segment>)}
            </>)}
          </Modal>
        )
      }

      {props?.procedureDetails?.lastApprovedVersion && (
        <ChangeLog
          visible={changeLog}
          setShowChangeLog={setShowChangeLog}
          form={form}
          steps={false}
        />
      )}
    </div>
  );
};
//editStep & stepEdit are two different redux store , editstep is used to enable editing the step while stepEdit is to check if editing happened in step
const mapStateToProps = (state) => {
  return {
    stepDetails: state.stepDetails,
    template: state.template,
    editStep: state.editStep,
    procedureDetails: state.procedureDetails,
    editedStepIds: state.editedStepIds,
    isReviewer: state.isReviewer,
    isNewDocument: state.isNewDocument,
    isFromFeedback: state.isFromFeedback,
    feedback: state.feedback,
    feedbackData: state.feedbackData,
    docMedia: state.docMedia,
    enablePreviewButton: state.enablePreviewButton,
    editForm: state.editForm,
    stepOrdered: state.stepOrdered,
    stepEdit: state.stepEdit,
    showPopupRedux: state.showPopupRedux,
    mediarender: state.mediarender,
    blankField: state.blankField,
    prevDocData: state.prevDocData,
    featureFlag: state.featureFlag,
    changeIsThere:state.changeIsThere,
    aimodal: state.aimodal,
  };
};

export default connect(mapStateToProps, {
  addStep,
  setSelectedTemplate,
  setEnableEditStep,
  setProcedureDetails,
  resetProcedureDetails,
  saveDocumentMedia,
  setPreview,
  resetEditStepIds,
  saveEditedStepIds,
  resetEditStep,
  saveIsReviewer,
  setNewDocument,
  saveDocumentfeedback,
  resetDocumentMedia,
  resetFeedback,
  setEnablePreview,
  setFormEdit,
  passMediaURL,
  setBlankFields,
  showPopupAction,
  mediafiletorender,
  savePrevData,
  setStepFormEdit,
  aiButtonon,resetStepMediaFiles
})(CreateInstructionsForm);
