import React, { useState } from "react";
import "./styles.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { uploadMedia } from "../../apiCall";
// import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
// import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

const FroalaEditorViewOnly = (props) => {
    const [defaultContent, setDefaultContent] = useState(props.content?.length > 0 ? props.content : "");

    const config = {
        key: "4NC5fB4D4C3E3C2C4A3D-13TMIBDIa2NTMNZFFPFZe2a1Id1f1I1fA8D6C4B4G3H3G2A18A15B6==",
        attribution: false,
    };

    return (
        <div className="EditorComponent">
            <FroalaEditorView
                model={defaultContent}
                config={config}
            />
        </div>
    );
};

export default FroalaEditorViewOnly;
