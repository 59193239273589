import React, { useState, useEffect } from "react";
import { Button, Image, List } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getNotifications,
  markNotificationRead,
  getDocumentById,
  getStepsByDocumentId,
  readAllNotifications,
} from "../apiCall";
import userDummy from "../../assets/images/user-dummy.png";
import {
  setSelectedTemplate,
  setProcedureDetails,
  updateDefaultStep,
  addStep,
  setUnReadNotification,
} from "../../redux/actions/index";
import notificationsIcon from "../../assets/images/notification.png";

/* This is the component to show the notification messages */
function Notifications(props) {
  const [notifications, setNotifications] = useState([]); // notification array
  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;

  /* To get the notification data from server */
  const getNotification = () => {
    var notifications = [];
    getNotifications()
      .then((response) => {
        const notificationCollection = response.data.data.notifications;
        notificationCollection.forEach((data) => {
          if (data.read && validateDate(data.createTime)) {
            notifications.push(data);
          } else if (!data.read) {
            notifications.push(data);
          }
        });
        setNotifications(notifications);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDate = (date) => {
    const timeDiffInMs = Date.now() - new Date(date).getTime();
    if (timeDiffInMs >= thirtyDaysInMs) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    getNotification();
    window.scrollTo(0, 0);
  }, [props.count]);

  /* Date to present in the UI */
  const getDate = (dateUTC) => {
    return (
      dateUTC.getDate() +
      "/" +
      (dateUTC.getMonth() + 1) +
      "/" +
      dateUTC.getFullYear()
    );
  };

  /* Time to present in the UI */
  const getTime = (dateUTC) => {
    if (dateUTC.getMinutes().toString().length === 1) {
      return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
    } else {
      return dateUTC.getHours() + ":" + dateUTC.getMinutes();
    }
  };

  /* This is to format the message and show it in the UI based on the type of notification */
  const getMessage = (type) => {
    switch (type) {
      case "DOC_APPROVED":
        return "Approved the document";
      case "DOC_PUBLISHED":
        return "Published the document";
      case "DOC_SUBMITTED":
        return "Submitted the document";
      case "DOC_UPDATED":
        return "Updated the document";
      case "NEW_FEEDBACK_COMMENTS":
        return "Added feedback comments to the document";
      case "NEW_REVIEW_COMMENTS":
        return "Added review comments to the document";
      default:
        return "assigned a task to you ";
    }
  };

  /* Get the height of the notification so that background can be updated for CSS */
  const getHeight = () => {
    return window.outerHeight > notifications.length * 100
      ? window.outerHeight + "px"
      : notifications.length * 100 + "px";
  };

  /* Mark the notification as read */
  const markasRead = (notificationClicked) => {
    markNotificationRead("/" + notificationClicked.notificationId + "/read");
  };

  /* Function called when notification is clicked and then based on the type the variables are set */
  const notificationClickedFn = (notificationClicked) => {
    markasRead(notificationClicked); // notification is marked as read
    getNotification();
    if (props.count === 1) {
      props.setUnReadNotification(0);
    }
  };

  const handleDocumentClick = (document) => {
    notificationClickedFn(document);
    getStoreData(document);
  };

  const getStoreData = async (data) => {
    await getDocumentById(data.documentId).then((response) => {
      props.setSelectedTemplate(response.data.data.document.documentType);
      response.data.data.document.media.forEach((file) => {
        if (file.fileName) {
          const ext = file.fileName.split(".").pop();
          file.extention = ext;
        }
      });
      const data = {
        procedureTitle: response.data.data.document.procedureTitle,
        procedureDescription: response.data.data.document.procedureDescription,
        status: response.data.data.document.status,
        category: response.data.data.document.category,
        documentId: response.data.data.document.documentId,
        media: response.data.data.document.media,
      };
      props.setProcedureDetails(data);
    });
    await getStepsByDocumentId(data.documentId).then((response) => {
      const data = response.data.data.steps;
      data.forEach((step, index) => {
        if (index === 0) {
          props.updateDefaultStep(step);
        } else {
          props.addStep(step);
        }
      });

      setTimeout(() => {
        props.history.push(`/Preview`);
      }, 1000);
    });
  };

  const handleReadAll = async () => {
    await readAllNotifications(true);
    getNotification();
    props.setUnReadNotification(0);
  };

  return (
    <div className="notificationsContainer" style={{ height: "100%" }}>
      {notifications.length === 0 && props.count === 0 && (
        <div className="notification-img-wrap">
          <div>
            <img src={notificationsIcon} style={{ height: "6rem" }} />
          </div>
          <div>
            <h3 style={{ color: "#0971CE", margin: "0" }}>
              No Notifications Recieved
            </h3>
          </div>
        </div>
      )}
      {notifications.length > 0 && (
        <>
          <div className="read-all-btn-wrap ">
            <Button
              className="read-all-btn"
              onClick={() => props.history.goBack()}
            >
              Back
            </Button>
            <Button className="read-all-btn" onClick={() => handleReadAll()}>
              Read All
            </Button>
          </div>
          <List className="notification-list-wrap">
            {notifications.map((eachNotification, i) => {
              return (
                <List.Item
                  key={i}
                  className={eachNotification.read ? "read" : "unread"}
                >
                  {eachNotification.source.avatar !== "" ? (
                    <Image
                      avatar
                      src={eachNotification.source.avatar}
                      onClick={() =>
                        props.history.push(
                          `/userprofile/id=${eachNotification.source.id}`
                        )
                      }
                    />
                  ) : (
                    <Image
                      avatar
                      src={userDummy}
                      onClick={() =>
                        props.history.push(
                          `/userprofile/id=${eachNotification.source.id}`
                        )
                      }
                    />
                  )}

                  <List.Content
                    onClick={() => notificationClickedFn(eachNotification)}
                  >
                    <List.Header>
                      {/*{eachNotification.source.name}*/}
                      <span>
                        {" "}
                        <b>{" " + eachNotification.source.name + " "}</b> has
                        {" " + getMessage(eachNotification.type) + " "}
                        <a
                          style={{ textDecoration: "underline" }}
                          onClick={() => handleDocumentClick(eachNotification)}
                        >
                          {eachNotification.title}
                        </a>
                      </span>
                    </List.Header>
                    <List.Description>
                      <span>
                        {" "}
                        {getDate(new Date(eachNotification.createTime))}{" "}
                      </span>
                      <span> at </span>
                      <span>
                        {" "}
                        {getTime(new Date(eachNotification.createTime))}{" "}
                      </span>
                    </List.Description>
                  </List.Content>
                </List.Item>
              );
            })}
          </List>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.userInfo.userId,
    count: state.count,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setSelectedTemplate,

    setProcedureDetails,
    addStep,
    updateDefaultStep,
    setUnReadNotification,
  })(Notifications)
);
