import React, { useEffect, useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDocumentById,
  getStepsByDocumentId,
  updateFeedbackStatus,
} from "../apiCall";
import {
  setProcedureDetails,
  setSelectedTemplate,
  updateDefaultStep,
  setFeedback,
  saveDocumentfeedback,
  addStep,
} from "../../redux/actions/index";

const Feedbackpopup = (props) => {
  const [showModal, setShowModal] = useState(false);
  const handleEditdocument = async (data) => {
    await getStoreData(data);
    props.saveDocumentfeedback(props.feedbackData);
  };

  useEffect(() => {
    if (props.feedbackData.media) {
      props.feedbackData.media.forEach((file) => {
        if (file.fileName) {
          const ext = file.fileName.split(".").pop();
          file.extention = ext;
        }
      });
    }
  }, [props.feedbackData.media]);

  const getStoreData = async (data) => {
    await getDocumentById(data[0].documentId).then((response) => {
      props.setSelectedTemplate(response.data.data.document.documentType);
      const data = {
        procedureTitle: response.data.data.document.procedureTitle,
        procedureDescription:
          typeof response.data.data.document.procedureDescription === "string"
            ? JSON.parse(response.data.data.document.procedureDescription)
            : response.data.data.document.procedureDescription,
        status: response.data.data.document.status,
        category: response.data.data.document.category,
        documentId: response.data.data.document.documentId,
      };
      props.setProcedureDetails(data);
    });
    await getStepsByDocumentId(data[0].documentId).then((response) => {
      const data = response.data.data.steps;
      data.forEach((step, index) => {
        if (index === 0) {
          props.updateDefaultStep(step);
        } else {
          props.addStep(step);
        }
      });
      setTimeout(() => {
        props.history.push(`/CreateInstructionsForm`);
      }, 1000);
    });
  };

  const handleDecline = async () => {
    const data = {
      status: "DECLINED",
    };
    setShowModal(false);
    await updateFeedbackStatus(
      props.feedbackData.documentId,
      props.feedbackData.stepId[0],
      props.feedbackData.commentId,
      data
    );
    props.handleClose();
  };

  // Render the images aganist the selected step
  const renderDownloadedImages = () => {
    var displayImages = [];
    if (props.feedbackData.media?.length > 0) {
      for (let i = 0; i < props.feedbackData.media.length; i++) {
        if (
          props.feedbackData.media[i].extention === "png" ||
          props.feedbackData.media[i].extention === "PNG" ||
          props.feedbackData.media[i].extention === "jpeg" ||
          props.feedbackData.media[i].extention === "jpg" ||
          props.feedbackData.media[i].extention === "JPG" ||
          props.feedbackData.media[i].extention === "JPEG"
        )
          displayImages.push(
            <>
              <img
                className="image-display"
                src={props.feedbackData.media[i].url}
                alt=""
              />
            </>
          );
      }
    }
    return displayImages;
  };

  // Render the videos aganist the selected step
  const renderDownloadedVideos = () => {
    var displayVideos = [];
    // Render files downloaded
    for (let i = 0; i < props.feedbackData.media.length; i++) {
      if (
        props.feedbackData.media[i].extention === "mp4" ||
        props.feedbackData.media[i].extention === "MOV" ||
        props.feedbackData.media[i].extention === "mov"
      ) {
        displayVideos.push(
          <video className="video-display" controls>
            <source
              className="video-display"
              src={props.feedbackData.media[i].url}
              alt=""
              type="video/mp4"
            />
          </video>
        );
      }
    }
    return displayVideos;
  };

  return (
    <>
      <Modal
        className="modal-feedback"
        open={props.popupVisible}
        onClose={() => props.handleClose()}
      >
        <div className="modal-heading-feedback">
          <div className="modal-feedback-heading">
            <h3>Feedback Type: {props.feedbackData.typeOfFeedback}</h3>
            <button
              style={{ alignItems: "end" }}
              onClick={() => props.handleClose()}
            >
              X
            </button>
          </div>
          <h4>Feedback Title : {props.feedbackData.summaryOfFeedback}</h4>
          <h5>Section : {props.feedbackData.section[0]}</h5>
          <h5>Description</h5>
          <p>{props.feedbackData.brief}</p>
          {props.feedbackData?.media?.length > 0 && <h5>Attachments</h5>}
          <div id="files">
            <ul style={{ listStyle: "none" }}>
              {renderDownloadedImages().length > 0 &&
                props.feedbackData.media?.length > 0 && (
                  <li>{renderDownloadedImages()}</li>
                )}
              {props.feedbackData.media?.length > 0 && (
                <li>{renderDownloadedVideos()}</li>
              )}
            </ul>
          </div>
        </div>
        <div className="modal-action-feedback">
          {props.history.location.pathname === "/CreateInstructionsForm" ||
          props.feedbackData.status === "DECLINED" ||
          props.feedbackData.status === "APPROVED" ? (
            <Button
              className="modal-button-option-decline"
              onClick={() => props.handleClose()}
            >
              Cancel
            </Button>
          ) : (
            <>
              <Button
                className="modal-button-option-decline"
                onClick={() => setShowModal(true)}
              >
                Decline
              </Button>
              <Button
                className="modal-button-option-gotodoc"
                onClick={() => handleEditdocument(props.feedback)}
              >
                Open Document
              </Button>
            </>
          )}
        </div>
      </Modal>
      {showModal && (
        <Modal
          className="modal-feedback-decline"
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <div className="modal-heading-delete">
            <div>
              <h3>Confirm Decline</h3>
              <p>Are you sure you want to decline the feedback?</p>
            </div>
            <button
              className="modal-feedback-decline-button"
              onClick={() => setShowModal(false)}
            >
              X
            </button>
          </div>
          <div className="modal-action-delete">
            <Button
              className="modal-button-option-del1"
              onClick={() => setShowModal(false)}
            >
              No
            </Button>
            <Button
              className="modal-button-option-del2"
              onClick={() => handleDecline()}
            >
              Yes
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    documentList: state.documentList,
    isReviewer: state.isReviewer,
    procedureDetails: state.procedureDetails,
    stepDetails: state.stepDetails,
    template: state.template,
    feedback: state.feedback,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    setProcedureDetails,
    setSelectedTemplate,
    updateDefaultStep,
    setFeedback,
    addStep,
    saveDocumentfeedback,
  })(Feedbackpopup)
);
